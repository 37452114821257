import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import vaultDesireStrategyABI from 'config/abi/vaultDesireStrategy.json'
import vaultStrategyMasterchefABI from 'config/abi/vaultStrategyMasterchef.json'
import multicall from 'utils/multicall'
import { getMasterChefAddress } from 'utils/addressHelpers'
import vaultsConfig from 'config/constants/vaults'
import { QuoteToken } from '../../config/constants/types'

const vaultABIs = {
  vaultDesireStrategy: vaultDesireStrategyABI,
  vaultStrategyMasterchef: vaultStrategyMasterchefABI,
}

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchVaults = async () => {
  const data = await Promise.all(
    vaultsConfig.map(async (vaultConfig) => {
      const lpAdress = vaultConfig.lpAddresses[CHAIN_ID]
      const calls = [
        // Balance of token in the LP contract
        {
          address: vaultConfig.tokenAddresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of quote token on LP contract
        {
          address: vaultConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: vaultConfig.isTokenOnly ? vaultConfig.tokenAddresses[CHAIN_ID] : lpAdress,
          name: 'balanceOf',
          params: [getMasterChefAddress()],
        },
        {
          address: lpAdress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: vaultConfig.tokenAddresses[CHAIN_ID],
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: vaultConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'decimals',
        },
        {
          address: vaultConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'balanceOf',
          params: [vaultConfig.strategyAddresses[CHAIN_ID]],
        },
      ]

      const [
        tokenBalanceLP,
        quoteTokenBalanceLP,
        lpTokenBalanceMCFarm,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
        lpQuoteBalanceMC,
      ] = await multicall(erc20, calls)

      let lpTokenBalanceMC
      let buyBackAmount = new BigNumber(0)

      if (vaultConfig.abiFile === 'vaultStrategyMasterchef') {
        [lpTokenBalanceMC, buyBackAmount] = await multicall(vaultABIs[vaultConfig.abiFile], [
          {
            address: vaultConfig.strategyAddresses[CHAIN_ID],
            name: 'wantLockedTotal',
          },
          {
            address: vaultConfig.strategyAddresses[CHAIN_ID],
            name: 'buyBackAmount',
          },
        ])
      }

      if (vaultConfig.abiFile === 'vaultDesireStrategy') {
        [lpTokenBalanceMC] = await multicall(vaultABIs[vaultConfig.abiFile], [
          {
            address: vaultConfig.strategyAddresses[CHAIN_ID],
            name: 'sharesTotal',
          },
        ])
      }

      let tokenAmount
      let lpTotalInQuoteToken
      let tokenPriceVsQuote

      // Vault
      if (vaultConfig.isTokenOnly) {
        tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals))

        if (vaultConfig.tokenSymbol === QuoteToken.USDC && vaultConfig.quoteTokenSymbol === QuoteToken.USDC) {
          tokenPriceVsQuote = new BigNumber(1)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBalanceLP).div(new BigNumber(tokenBalanceLP))
        }
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
      } else {
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

        // Total value in staking in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP)
          .div(new BigNumber(10).pow(tokenDecimals))
          .times(new BigNumber(2))
          .times(lpTokenRatio)

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
        const quoteTokenAmount = new BigNumber(quoteTokenBalanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenRatio)

        if (tokenAmount.comparedTo(0) > 0) {
          tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBalanceLP)
            .div(new BigNumber(tokenBalanceLP))
            .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals))
        }
      }

      let rewardRate
      if (vaultConfig.abiFile === 'vaultDesireStrategy' && new BigNumber(lpTokenBalanceMC).comparedTo(0) > 0) {
        rewardRate = new BigNumber(lpQuoteBalanceMC)
          .div(new BigNumber(lpTokenBalanceMC))
          .times(new BigNumber(10).pow(18 - quoteTokenDecimals))
      }

      let tokenAmountFarm
      let lpTotalInQuoteTokenFarm
      let tokenPriceVsQuoteFarm

      // Farm
      if (vaultConfig.isTokenOnly) {
        tokenAmountFarm = new BigNumber(lpTokenBalanceMCFarm).div(new BigNumber(10).pow(tokenDecimals))

        if (vaultConfig.tokenSymbol === QuoteToken.USDC && vaultConfig.quoteTokenSymbol === QuoteToken.USDC) {
          tokenPriceVsQuoteFarm = new BigNumber(1)
        } else {
          tokenPriceVsQuoteFarm = new BigNumber(quoteTokenBalanceLP).div(new BigNumber(tokenBalanceLP))
        }
        lpTotalInQuoteTokenFarm = tokenAmountFarm.times(tokenPriceVsQuoteFarm)
      } else {
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatioFarm = new BigNumber(lpTokenBalanceMCFarm).div(new BigNumber(lpTotalSupply))

        // Total value in staking in quote token value
        lpTotalInQuoteTokenFarm = new BigNumber(quoteTokenBalanceLP)
          .div(new BigNumber(10).pow(tokenDecimals))
          .times(new BigNumber(2))
          .times(lpTokenRatioFarm)

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmountFarm = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatioFarm)
        const quoteTokenAmountFarm = new BigNumber(quoteTokenBalanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenRatioFarm)

        if (tokenAmountFarm.comparedTo(0) > 0) {
          tokenPriceVsQuoteFarm = quoteTokenAmountFarm.div(tokenAmountFarm)
        } else {
          tokenPriceVsQuoteFarm = new BigNumber(quoteTokenBalanceLP)
            .div(new BigNumber(tokenBalanceLP))
            .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals))
        }
      }

      const [info, totalAllocPoint, desirePerBlock] = await multicall(masterchefABI, [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [vaultConfig.farmPid],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
        {
          address: getMasterChefAddress(),
          name: 'desirePerBlock',
        },
      ])

      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

      return {
        ...vaultConfig,
        tokenAmount: tokenAmount.toJSON(),
        // quoteTokenAmount: quoteTokenAmount,
        lpTotalInQuoteToken: lpTotalInQuoteToken
          .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals))
          .toJSON(),
        lpTotalInQuoteTokenFarm: lpTotalInQuoteTokenFarm
          .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals))
          .toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight.toNumber(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info.depositFeeBP,
        desirePerBlock: new BigNumber(desirePerBlock).toNumber(),
        rewardRate: rewardRate && rewardRate.toJSON(),
        buyBackAmount: new BigNumber(buyBackAmount).toJSON(),
      }
    }),
  )
  return data
}

export default fetchVaults
