import React, { useContext } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/hooks'
import { Menu as UikitMenu, MenuEntry } from '@pancakeswap-libs/uikit'
// import contracts from '../../config/constants/contracts'
import BigNumber from 'bignumber.js'
// import config from './config'
// import { getRefUrl } from '../../utils'
import contracts from '../../config/constants/contracts'

// const refer = getRefUrl()
// hack to force the set of the cockie.
// const refParam = refer === '0x0000000000000000000000000000000000000000' ? '' : `?ref=${refer}`
// console.log('ref', refParam)

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const token = contracts.cake[process.env.REACT_APP_CHAIN_ID]
  const mc = contracts.masterChef[process.env.REACT_APP_CHAIN_ID]

  const originalPriceUsd = usePriceCakeBusd()
  let cakePriceUsd = originalPriceUsd
  if (process.env.REACT_APP_PRICE_DEFAULT) {
    cakePriceUsd = new BigNumber(process.env.REACT_APP_PRICE_DEFAULT)
  }

  const config = () => {
    const cfg: MenuEntry[] = [
      {
        label: 'Home',
        icon: 'HomeIcon',
        href: props.onPresale ? '/home' : '/',
      },
      {
        label: 'Desire Kingdom',
        icon: 'IfoIcon',
        href: '/desire-kingdom',
        // tag: {
        //   title: 'live',
        //   variant: 'secondary',
        // },
      },
      {
        label: 'Farms',
        icon: 'FarmIcon',
        href: '/farms',
        tag: {
          title: 'off',
          variant: 'secondary',
        },
      },
      {
        label: 'Pools',
        icon: 'PoolIcon',
        href: '/pools',
        tag: {
          title: 'off',
          variant: 'secondary',
        },
      },
      {
        label: 'Premier Vaults',
        icon: 'VaultsIcon',
        href: '/vaults',
        tag: {
          title: 'off',
          variant: 'secondary',
        },
      },
      {
        label: 'Vaults',
        icon: 'VaultsIcon',
        href: '/vaults-crystl.finance',
        tag: {
          title: 'by Crystl Finance',
          variant: 'secondary',
        },
      },
      {
        label: 'NFT',
        icon: 'NftIcon',
        items: [
          {
            label: 'Sale (sold out)',
            href: '/nft-sale',
          },
          {
            label: 'Merge (coming soon)',
            href: '#',
          },
          {
            label: 'Cards',
            href: '/nft-cards',
          },
          {
            label: 'Collection',
            href: '/nft-collection',
          },
          {
            label: 'Ranking',
            href: '/nft-ranking',
          },
          {
            label: 'Battles (coming soon)',
            href: '/nft-battles',
          },
          {
            label: 'Airdrop (open)',
            href: '/nft-airdrop',
          },
        ],
      },
      {
        label: 'ANTI-BOT',
        icon: 'AuditIcon',
        href: '/anti-bot',
      },
      {
        label: 'Tokens',
        icon: 'MetamaskIcon',
        href: 'https://metamask.sandman.finance',
      },
      // {
      //   label: 'Referral',
      //   icon: 'ReferralIcon',
      //   href: '/referral',
      // },
      // {
      //   label: 'Lottery (coming soon)',
      //   icon: 'TicketIcon',
      //   href: '/lottery',
      // },
      // {
      //   label: 'Prediction (coming soon)',
      //   icon: 'PredictionIcon',
      //   href: '/lottery',
      // },
      // {
      //   label: 'NFT',
      //   icon: 'NftIcon',
      //   href: '/nft',
      // },
      // {
      //   label: 'Features',
      //   icon: 'FeaturesIcon',
      //   items: [
      //     {
      //       label: 'Automatic LP',
      //       href: 'https://docs.sandman.farm/tokenomics/automatic-liquidity',
      //     },
      //     {
      //       label: 'Automatic Burning',
      //       href: 'https://docs.sandman.farm/tokenomics/automatic-burning',
      //     },
      //     {
      //       label: 'Deposit Shield',
      //       href: 'https://docs.sandman.farm/deposit-shield',
      //     },
      //     {
      //       label: 'Anti-Whale',
      //       href: 'https://docs.sandman.farm/tokenomics/anti-whale',
      //     },
      //   ],
      // },
      // {
      //   label: 'Charts',
      //   icon: 'InfoIcon',
      //   items: [
      //     {
      //       label: 'PooCoin',
      //       href: `https://polygon.poocoin.app/tokens/${token}`,
      //     },
      //     {
      //       label: 'Arken',
      //       href: `https://swap.arken.finance/tokens/polygon/${token}`,
      //     },
      //   ],
      // },
      {
        label: 'Listings',
        icon: 'ListingIcon',
        items: [
          {
            label: 'Vfat',
            href: 'https://vfat.tools/polygon/desirefarm/',
          },
          {
            label: 'DappRadar',
            href: 'https://dappradar.com/polygon/defi/desire',
          },
          {
            label: 'PooCoin',
            href: `https://polygon.poocoin.app/tokens/${token}`,
          },
          {
            label: 'Arken',
            href: `https://swap.arken.finance/tokens/polygon/${token}`,
          },
      //     {
      //       label: 'FarmScan',
      //       href: `https://polygon.farmscan.io/address/${mc}`,
      //     },
        ],
      },
      {
        label: 'Sandman',
        icon: 'ReferralIcon',
        items: [
          {
            label: 'The Endless',
            href: '/the-endless',
          },
          {
            label: 'Tutorials',
            href: '/tutorials',
          },
          {
            label: 'Wallpapers',
            href: '/wallpapers',
          },
          {
            label: 'Team',
            href: '/team',
          },
        ],
      },
    ]

    if (props.onPresale) {
      cfg.splice(0, 0, 
        {
          label: 'Pre-sale',
          icon: 'NftIcon',
          href: '/',
        }
      )
    } else {
      cfg.push(
        {
          label: 'Pre-sale (ended)',
          icon: 'NftIcon',
          href: '/presale',
        }
      )
    }

    if (originalPriceUsd.isNaN()) {
      cfg.push(
        {
          label: 'Trade (coming soon)',
          icon: 'TradeIcon',
          href: '#',
        }
      )
    } else {
      cfg.splice(props.onPresale ? 3 : 2, 0,
        {
          label: 'Trade',
          icon: 'TradeIcon',
          items: [
            {
              label: 'Exchange',
              href: `https://quickswap.exchange/#/swap?outputCurrency=${token}`,
            },
            {
              label: 'Liquidity',
              href: `https://quickswap.exchange/#/add/${token}/ETH`,
            },
          ],
        }
      )
    }

    return cfg
  }

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config()}
      priceLink={`https://swap.arken.finance/tokens/polygon/${token}`}
      rugDocLink="https://rugdoc.io/project/desire-farm/"
      rugDocText="KYC / LOW RISK"
      auditSCLink="https://audit.sc/audits/desire-project-audit-review/"
      auditSCText="LOW RISK"
      auditText="ANTI-BOT"
      auditLink="/anti-bot"
      {...props}
    />
  )
}

export default Menu
