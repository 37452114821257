import contracts from './contracts'
import { VaultConfig, QuoteToken } from './types'

const vaults: VaultConfig[] = [
  {
    pid: 1,
    farmPid: 1,
    lpSymbol: 'DESIRE-MATIC',
    decimal: 18,
    lpAddresses: {
      137: '0x8da8e328114C490809330F767281e113eAAE0217',
    },
    tokenSymbol: 'DESIRE',
    tokenAddresses: {
      137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    },
    quoteTokenSymbol: QuoteToken.MATIC,
    quoteTokenAdresses: contracts.wmatic,
    earnSymbol: 'DESIRE-MATIC',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x906B0F882879ec99FC8eA7324d41329222c119CE',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 0,
    farmPid: 0,
    lpSymbol: 'DESIRE-USDC',
    decimal: 18,
    lpAddresses: {
      137: '0x5C2d9217aeF2B7478F3Cf301619CAd236c345567',
    },
    tokenSymbol: 'DESIRE',
    tokenAddresses: {
      137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESIRE-USDC',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x14d847A8a3b034dA72940Ff8300603D25824DEA9',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 5,
    farmPid: 2,
    isTokenOnly: true,
    lpSymbol: 'DESIRE',
    decimal: 18,
    lpAddresses: {
      137: '0x5C2d9217aeF2B7478F3Cf301619CAd236c345567',
    },
    tokenSymbol: 'DESIRE',
    tokenAddresses: {
      137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESIRE',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x502236B553FB34d6F2c9EbbC91F378d9fAb82093',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 4,
    farmPid: 15,
    isTokenOnly: true,
    lpSymbol: 'DESTRUCTION',
    decimal: 18,
    lpAddresses: {
      137: '0x287bD6518aE54a4299BD766faa6DF3ED795fB6C1',
    },
    tokenSymbol: 'DESTRUCTION',
    tokenAddresses: {
      137: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESTRUCTION',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x9411Ba2485Ac04E1cAC640FceC80b95d035feD60',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 3,
    farmPid: 14,
    isTokenOnly: true,
    lpSymbol: 'DESPAIR',
    decimal: 18,
    lpAddresses: {
      137: '0x92BB40fa63EB4f402Cae5bDDAb3369a4Fb3e33bF',
    },
    tokenSymbol: 'DESPAIR',
    tokenAddresses: {
      137: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESPAIR',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x47948E49378e72bc9276bE9985a97dc6f9C58205',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 2,
    farmPid: 13,
    isTokenOnly: true,
    lpSymbol: 'DELIRIUM',
    decimal: 18,
    lpAddresses: {
      137: '0x2F051dB1634eEa2081590860ab8E756D452f2239',
    },
    tokenSymbol: 'DELIRIUM',
    tokenAddresses: {
      137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DELIRIUM',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x739E2FD1B5c8C2c4324F0124F1df10e45A32bBdC',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
]

export default vaults
