import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}
export const getUsdcAddress = () => {
  return addresses.usdc[chainId]
}
export const getDeliriumAddress = () => {
  return addresses.delirium[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getVaultChefAddress = () => {
  return addresses.vaultChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getSandmanReferralAddress = () => {
  return addresses.desireReferral[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}
export const getLustTokenAddress = () => {
  return addresses.lustToken[chainId]
}
export const getLustSwapAddress = () => {
  return addresses.lustSwap[chainId]
}
export const getTheEndlessFactoryNFTAddress = () => {
  return addresses.theEndlessFactoryNFT[chainId]
}
export const getNftAirdropAddress = () => {
  return addresses.nftAirdrop[chainId]
}
export const getNftSaleAddress = () => {
  return addresses.nftSale[chainId]
}
