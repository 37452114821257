import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 1,
    risk: 5,
    from: 'QuickSwap',
    lpSymbol: 'DESIRE-MATIC LP',
    decimal: 18,
    lpAddresses: {
      137: '0x8da8e328114C490809330F767281e113eAAE0217',
    },
    tokenSymbol: 'DESIRE',
    tokenAddresses: {
      137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wmatic,
  },
  {
    pid: 0,
    risk: 5,
    from: 'QuickSwap',
    lpSymbol: 'DESIRE-USDC LP',
    decimal: 18,
    lpAddresses: {
      137: '0x5C2d9217aeF2B7478F3Cf301619CAd236c345567',
    },
    tokenSymbol: 'DESIRE',
    tokenAddresses: {
      137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 3,
    risk: 5,
    from: 'QuickSwap',
    lpSymbol: 'MATIC-USDC LP',
    decimal: 18,
    lpAddresses: {
      137: '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827',
    },
    tokenSymbol: 'MATIC',
    tokenAddresses: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 4,
    risk: 5,
    from: 'QuickSwap',
    lpSymbol: 'ETH-BTC LP',
    decimal: 18,
    lpAddresses: {
      137: '0xdc9232e2df177d7a12fdff6ecbab114e2231198d',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    },
    quoteTokenSymbol: QuoteToken.BTC,
    quoteTokenAdresses: contracts.wbtc,
  },
  {
    pid: 6,
    risk: 5,
    from: 'QuickSwap',
    lpSymbol: 'ETH-MATIC LP',
    decimal: 18,
    lpAddresses: {
      137: '0xadbF1854e5883eB8aa7BAf50705338739e558E5b',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.matic,
  },
  {
    pid: 5,
    risk: 5,
    from: 'QuickSwap',
    lpSymbol: 'ETH-USDC LP',
    decimal: 18,
    lpAddresses: {
      137: '0x853Ee4b2A13f8a742d64C8F088bE7bA2131f670d',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 16,
    risk: 5,
    from: 'Polycat',
    lpSymbol: 'DESIRE-USDC LP',
    decimal: 18,
    lpAddresses: {
      137: '0x85404bF05D4DF6d0f22a4d2B456B739528aDDb46',
    },
    tokenSymbol: 'DESIRE',
    tokenAddresses: {
      137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 2,
    risk: 5,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'DESIRE',
    decimal: 18,
    lpAddresses: {
      137: '0x5C2d9217aeF2B7478F3Cf301619CAd236c345567',
    },
    tokenSymbol: 'DESIRE',
    tokenAddresses: {
      137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 15,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'DESTRUCTION',
    decimal: 18,
    lpAddresses: {
      137: '0x287bD6518aE54a4299BD766faa6DF3ED795fB6C1',
    },
    tokenSymbol: 'DESTRUCTION',
    tokenAddresses: {
      137: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 14,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'DESPAIR',
    decimal: 18,
    lpAddresses: {
      137: '0x92BB40fa63EB4f402Cae5bDDAb3369a4Fb3e33bF',
    },
    tokenSymbol: 'DESPAIR',
    tokenAddresses: {
      137: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 13,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'DELIRIUM',
    decimal: 18,
    lpAddresses: {
      137: '0x2F051dB1634eEa2081590860ab8E756D452f2239',
    },
    tokenSymbol: 'DELIRIUM',
    tokenAddresses: {
      137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 9,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'WETH',
    decimal: 18,
    lpAddresses: {
      137: '0x853Ee4b2A13f8a742d64C8F088bE7bA2131f670d',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 10,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'WBTC',
    decimal: 8,
    lpAddresses: {
      137: '0xf6a637525402643b0654a54bead2cb9a83c8b498',
    },
    tokenSymbol: 'BTC',
    tokenAddresses: {
      137: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 7,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'WMATIC',
    decimal: 18,
    lpAddresses: {
      137: '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827',
    },
    tokenSymbol: 'MATIC',
    tokenAddresses: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 11,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'USDC',
    decimal: 6,
    lpAddresses: {
      137: '0x2cf7252e74036d1da831d11089d326296e64a728',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 8,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'DAI',
    decimal: 18,
    lpAddresses: {
      137: '0x59153f27eefe07e5ece4f9304ebba1da6f53ca88',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 12,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'LINK',
    decimal: 18,
    lpAddresses: {
      137: '0x70ceE55c98F6DA2685807611f115eA737d4a248E',
    },
    tokenSymbol: 'LINK',
    tokenAddresses: {
      137: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 17,
    risk: 3,
    from: 'Polycat',
    isTokenOnly: true,
    lpSymbol: 'FISH',
    decimal: 18,
    lpAddresses: {
      137: '0xbFf681C59158EA5Cf7d29e439cB701a9bB8B79F8',
    },
    tokenSymbol: 'FISH',
    tokenAddresses: {
      137: '0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 18,
    risk: 3,
    from: 'Polycat',
    isTokenOnly: true,
    lpSymbol: 'PAW',
    decimal: 18,
    lpAddresses: {
      137: '0x4Cd2b8b7E00ac8EB544c51c4B1F0Bd39868A89dF',
    },
    tokenSymbol: 'PAW',
    tokenAddresses: {
      137: '0xBC5b59EA1b6f8Da8258615EE38D40e999EC5D74F',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
]

export default farms
