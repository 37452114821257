import { PoolConfig, PoolCategory } from './types'
// import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 3,
    tokenName: 'DAI',
    stakingTokenName: 'DESIRE',
    stakingTokenAddress: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    stakingTokenLpAddress: '0x8da8e328114C490809330F767281e113eAAE0217',
    stakingTokenQuoteTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    contractAddress: {
      137: '0x0AeB707C81F1Ae6870BF359a43095Dc100A7c3cE',
    },
    lpAddress: {
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
    },
    tokenAddress: {
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://makerdao.com/',
    harvest: true,
    tokenPerBlock: '0.01075268817',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 2000,
    withdrawLockup: 120,
    harvestLockup: 4,
    partnerColor: '208, 142, 33',
    experienceBalance: 5000,
  },
  {
    sousId: 2,
    tokenName: 'DAI',
    stakingTokenName: 'DESIRE',
    stakingTokenAddress: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    stakingTokenLpAddress: '0x8da8e328114C490809330F767281e113eAAE0217',
    stakingTokenQuoteTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    contractAddress: {
      137: '0x1cB330c4B4517736C5563F4E7048ed01389886Be',
    },
    lpAddress: {
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
    },
    tokenAddress: {
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://makerdao.com/',
    harvest: true,
    tokenPerBlock: '0.01829268293',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 1500,
    withdrawLockup: 48,
    harvestLockup: 4,
    partnerColor: '182, 25, 36',
    experienceBalance: 5000,
  },
  {
    sousId: 1,
    tokenName: 'DAI',
    stakingTokenName: 'LUST',
    stakingTokenAddress: '0x2133B706F31D796f9471c0840C31FF41A8b4543D',
    stakingTokenLpAddress: '0x1c50b2187B2fB666A7aA9CFbD032C5cb0B7fd189',
    stakingTokenQuoteTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    contractAddress: {
      137: '0x3ab0A1Accd275bA188633d5Aa3b8fD7f0f593c09',
    },
    lpAddress: {
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
    },
    tokenAddress: {
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://makerdao.com/',
    harvest: true,
    tokenPerBlock: '0.01965923984',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 1500,
    withdrawLockup: 48,
    harvestLockup: 4,
    partnerColor: '182, 25, 36',
    experienceBalance: 5000,
  },
]

export default pools
