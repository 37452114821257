export default {
  cake: {
    137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
  },
  masterChef: {
    137: '0xa6f5d8eD74eaab164cddAe9eabE1Fd2900132B8a',
  },
  vaultChef: {
    137: '0x10BBbA871D5b350d66435F95A5aEBe0094a6c9f3',
  },
  lottery: {
    137: '',
  },
  lustToken: {
    137: '0x2133B706F31D796f9471c0840C31FF41A8b4543D',
  },
  lustSwap: {
    137: '0x226EBA38edECa6bA2dBa66CaC8b6308B879a59e1',
  },
  theEndlessFactoryNFT: {
    137: '0x743F554f6AcCd4E452AF6C96c48B78E849d87316',
    // 137: '0x2487DF8D510cC0A9ec99fcE1F1d43adb3F6C731d', // Test
  },
  nftAirdrop: {
    137: '0xd516B24d299E58FdEd79F94388965ad6B9bc65EA',
  },
  nftSale: {
    137: '0xA7200d2672ae00452448540f6fB3fF7D40F6afD8',
  },
  desireReferral: {
    137: '',
  },
  lotteryNFT: {
    137: '',
  },
  mulltiCall: {
    137: '0xEF3d4160E3d193E3d1494a4A9cF9eEA05E63a324',
  },
  wmatic: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  matic: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  wbnb: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  bnb: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  wbtc: {
    137: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  },
  weth: {
    137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  eth: {
    137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  usdc: {
    137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  busd: {
    137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  usdt: {
    137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  },
  dai: {
    137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  },
  link: {
    137: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
  },
  delirium: {
    137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
  },
  despair: {
    137: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
  },
}
